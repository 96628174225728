import Service from "@/services/service";
import { set } from "lodash";

export default class OrganizationService extends Service {
  constructor() {
    super("organizations");
  }

  list(options) {
    return this.get("", options);
  }

  read(id, options) {
    return this.get(`/${id}`, options);
  }

  listStudies(id, options) {
    set(options, "params.types", ["tla", "ad"]);
    return this.get(`/${id}/studies`, options);
  }

  listPlans(id, options) {
    return this.get(`/${id}/plans`, options);
  }
}
