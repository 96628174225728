<template>
  <div class="min-h-screen flex place-items-center justify-center" v-if="!ready">
    <IconSpinner class="animate-spin w-12 h-12" />
  </div>
  <template v-else>
    <nav class="w-44 h-full fixed p-4 overflow-y-auto bg-secondary">
      <img alt="AirSage logo" class="h-10 mb-6 mx-auto" src="@/assets/img/logo_long_white.png" />
      <div class="flex flex-col justify-between space-y-2 divide-y divide-zinc-500">
        <ul class="space-y-1 font-medium">
          <li>
            <NavMenuItem to="studies">
              <template #icon>
                <IconStudies class="size-6" />
              </template>
            </NavMenuItem>
          </li>
          <!-- <li>
            <NavMenuItem to="study-areas">
              <template #icon>
                <IconMapMarker class="size-6" />
              </template>
            </NavMenuItem>
          </li> -->
          <li>
            <NavMenuItem to="plans">
              <template #icon>
                <IconPlan class="size-6" />
              </template>
            </NavMenuItem>
          </li>
          <li>
            <NavMenuItem to="account">
              <template #icon>
                <IconAccount class="size-6" />
              </template>
            </NavMenuItem>
          </li>
        </ul>
        <div class="pt-2">
          <div class="flex items-center p-2 text-zinc-400 rounded-lg hover:bg-zinc-50/5 hover:text-zinc-50 cursor-pointer" @click="logoutUser">
            <IconLogout class="size-6" />
            <span class="font-medium ml-3 capitalize">Logout</span>
          </div>
        </div>
      </div>
    </nav>
    <main class="ml-[176px]">
      <RouterView />
      <ToastAlert />
    </main>
  </template>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { RouterView } from "vue-router";
import { useAuth0 } from "@auth0/auth0-vue";
import { getCurrentScope } from "@sentry/vue";
import { get, nth } from "lodash";
import NavMenuItem from "@/components/NavMenuItem.vue";
import ToastAlert from "@/components/ToastAlert.vue";
import IconAccount from "@/components/icons/IconAccount.vue";
import IconPlan from "@/components/icons/IconPlan.vue";
// import IconMapMarker from "@/components/icons/IconMapMarker.vue";
import IconLogout from "@/components/icons/IconLogout.vue";
import IconStudies from "@/components/icons/IconStudies.vue";
import IconSpinner from "@/components/icons/IconSpinner.vue";
import {
  UsersService,
  OrganizationsService,
  ModulesService
} from "@/services";
import { useUserStore } from "@/stores/user";
import { useMainStore } from "@/stores/main";

const userStore = useUserStore();
const mainStore = useMainStore();
const {
  isLoading,
  isAuthenticated,
  user,
  getAccessTokenSilently,
  logout
} = useAuth0();

const ready = computed(() => !isLoading.value && isAuthenticated.value && userLoaded.value);
const userLoaded = ref(false);

const loadUser = async () => {
  try {
    const id = nth(get(user, "value.sub", "|").split('|'), 1);
    const token = await getAccessTokenSilently();

    const userData = await UsersService.read(id, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    const scope = getCurrentScope();
    const { email, name } = user.value;
    scope.setUser({ id, email, username: name });

    userStore.user = { ...userData, ...user.value };
    if (userStore.isAdmin) {
      userStore.organizations = await OrganizationsService.list({
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
    } else {
      userStore.organizations = await UsersService.listOrganizations(id, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
    }

    mainStore.modules = await ModulesService.list({
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  } catch (error) {
    console.error(error);
  }
};

watch(isLoading, async () => {
  if (!isLoading.value && isAuthenticated.value) {
    await loadUser();
    userLoaded.value = true;
  }
}, {
  immediate: true
});

const logoutUser = () => {
  logout({
    clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
    returnTo: `${window.location.origin}/logout`
  });
};
</script>

<style>
@import "@/assets/style/main.css";
@import 'maplibre-gl/dist/maplibre-gl.css';
</style>
