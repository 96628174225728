<template>
  <li class="hover:bg-zinc-300 hover:dark:bg-zinc-800 cursor-pointer">
    <RouterLink class="p-4 block w-full flex space-x-4 items-center justify-between" :to="{ name: 'study', params: { id: study.id } }">
      <div class="min-w-0">
        <div>{{ study.name }}</div>
        <div class="text-xs text-zinc-400 truncate" v-if="study.description">{{ studyTypeMap[study.type] }} - {{ study.description }}</div>
      </div>
      <div class="whitespace-nowrap" :class="`status-${kebabCase(study.status)}`">{{ startCase(camelCase(study.status)) }}</div>
    </RouterLink>
  </li>
</template>

<script setup>
import { RouterLink } from "vue-router";
import { camelCase, startCase, kebabCase } from "lodash";

defineProps({
  study: {
    type: Object,
    default: () => {}
  }
});

const studyTypeMap = {
  tla: "Targeted Location Analysis",
  ad: "Activity Density"
};
</script>