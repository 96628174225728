import { h } from "vue";
import { createRouter, createWebHistory, RouterView } from "vue-router";
import { authGuard } from "@auth0/auth0-vue";
import StudiesView from "@/views/StudiesView.vue";
import PlansView from "@/views/PlansView.vue";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [{
    path: "/",
    redirect: {
      name: "studies"
    }
  }, {
    path: "/studies",
    component: { render: () => h(RouterView) },
    children: [{
      path: "",
      name: "studies",
      component: StudiesView,
      beforeEnter: authGuard
    }, {
      path: "create",
      name: "create-study",
      component: () => import("@/views/CreateStudyView.vue"),
      beforeEnter: authGuard
    }, {
      path: "create-ad",
      name: "create-ad-study",
      component: () => import("@/views/CreateActivityDensityStudyView.vue"),
      beforeEnter: authGuard
    }, {
      path: ":id",
      name: "study",
      component: () => import("@/views/StudyView.vue"),
      beforeEnter: authGuard,
      children: [{
        name: "properties",
        path: "properties",
        component: () => import("@/components/StudyProperties.vue"),
      }, {
        name: "visualize",
        path: "visualize",
        component: () => import("@/components/VisualizeStudy.vue"),
      }]
    }]
  }, {
    path: "/plans",
    component: { render: () => h(RouterView) },
    children: [{
      path: "",
      name: "plans",
      component: PlansView,
      beforeEnter: authGuard
    }, {
      path: ":id",
      name: "plan",
      component: () => import("@/views/PlanView.vue"),
      beforeEnter: authGuard
    }]
  }, {
    path: "/study-areas",
    name: "study-areas",
    component: () => import("@/views/AccountView.vue"),
    beforeEnter: authGuard
  }, {
    path: "/account",
    name: "account",
    component: () => import("@/views/AccountView.vue"),
    beforeEnter: authGuard
  }, {
    path: "/callback",
    name: "callback",
    component: () => import("@/views/CallbackView.vue")
  }, {
    path: "/logout",
    redirect: "/"
  }]
});

export default router;
