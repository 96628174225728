<template>
  <Listbox v-model="value">
    <div class="relative">
      <ListboxButton class="relative w-full cursor-pointer rounded-lg flex space-x-2 py-2 pl-3 pr-10 text-left text-sm border border-zinc-400 dark:border-zinc-600 hover:bg-zinc-50/5">
        <IconOrganization class="w-5 h-5 text-zinc-500" />
        <span class="block truncate" v-if="value">{{ value.name }}</span>
        <span class="block" v-else>Select Organization</span>
        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <IconChevronDown class="h-5 w-5 text-zinc-400" aria-hidden="true" />
        </span>
      </ListboxButton>
      <transition leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <ListboxOptions class="absolute mt-1 bg-zinc-100 dark:bg-zinc-800 dark:border dark:border-zinc-600 max-h-60 overflow-auto rounded-lg py-1 shadow-lg text-sm top-full inset-x-0 z-20">
          <ListboxOption v-slot="{ active, selected }" v-for="org in userStore.organizations" :key="org.id" :value="org" as="template">
            <li :class="[
              active ? 'bg-zinc-300 dark:bg-zinc-800 text-zinc-700 dark:text-zinc-200' : 'text-zinc-700 dark:text-zinc-200',
              'relative cursor-pointer select-none py-2 pl-10 pr-4',
            ]">
              <span :class="[
                selected ? 'font-medium' : 'font-normal',
                'block truncate',
              ]">{{ org.name }}</span>
              <span v-if="selected" class="absolute inset-y-0 left-0 flex items-center pl-3 text-accent">
                <IconCheck class="h-5 w-5" aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script setup>
import { computed } from "vue";
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption
} from "@headlessui/vue";
import IconChevronDown from "@/components/icons/IconChevronDown.vue";
import IconCheck from "@/components/icons/IconCheck.vue";
import IconOrganization from "@/components/icons/IconOrganization.vue";
import { useUserStore } from "@/stores/user";

const userStore = useUserStore();

const props = defineProps({
  modelValue: {
    type: {},
    default: () => {}
  }
});

const emit = defineEmits(["update:modelValue"]);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  }
});
</script>