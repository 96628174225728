<template>
  <div>
    <div class="pb-1 font-medium text-lg">{{ title }}</div>
    <div class="h-96 cursor-default" ref="barChart"></div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { keys, isEmpty, values } from "lodash";
import { max, sum } from "simple-statistics";
import { scaleLinear, scaleBand } from "d3-scale";
import { select } from "d3-selection";
import { axisBottom } from "d3-axis";
import "d3-transition";

const props = defineProps({
  title: String,
  data: {
    type: Object,
    default: () => {}
  }
});

const barChart = ref(null);

async function drawbarChart() {
  if (!isEmpty(props.data)) {
    const darkMode = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;
    const width = barChart.value.clientWidth;
    const height = barChart.value.clientHeight;
    const padding = { top: 25, right: 20, bottom: 60, left: 20 };

    const total = sum(values(props.data).map((v) => +v));

    const x = scaleBand()
      .domain(keys(props.data))
      .range([padding.left, width - padding.right])
      .padding(0.1);
    const y = scaleLinear()
      .domain([0, max(values(props.data).map((v) => +v))]).nice()
      .range([height - padding.bottom, padding.top]);

    // create container
    select(barChart.value).selectChildren("*").remove();
    const svg = select(barChart.value).append("svg")
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [0, 0, width, height]);

    const bars = svg.append("g").selectAll().data(Object.entries(props.data)).enter().append("g");
    const valueLabels = svg.append("g").selectAll().data(Object.entries(props.data)).enter().append("g");

   const mouseoverHandle = (e, d) => {
      bars.selectAll("rect").filter((d2) => d[0] !== d2[0]).transition().duration(50).attr("opacity", 0.25);
      valueLabels.selectAll("text").filter((d2) => d[0] === d2[0]).transition().duration(50).attr("opacity", 1)
    };

    const mouseoutHandle = (e, d) => {
      bars.selectAll("rect").filter((d2) => d[0] !== d2[0]).transition().duration(50).attr("opacity", 1);
      valueLabels.selectAll("text").filter((d2) => d[0] === d2[0]).transition().duration(50).attr("opacity", 0)
    };

    // bars
    bars.append("rect")
      .attr("x", (d) => x(d[0]))
      .attr("y", (d) => y(d[1]))
      .attr("width", x.bandwidth())
      .attr("height", (d) => y(0) - y(d[1]))
      .attr("fill", "#85BB65")
      .on("mouseover", mouseoverHandle)
      .on("mouseout", mouseoutHandle);

    valueLabels.append("text")
      .text((d) => `${(d[1] / total * 100).toFixed(0)}%`)
      .attr("transform", (d) => `translate(${x(d[0]) + 5 + x.bandwidth() / 2}, ${y(d[1]) - 8})`)
      .style("text-anchor", "middle")
      .style("fill", darkMode ? "#E5E7EB" : "#3F3F46")
      .style("font-size", 20)
      .attr("opacity", 0)
      .on("mouseover", mouseoverHandle)
      .on("mouseout", mouseoutHandle);

    svg.append("g")
      .attr("transform", `translate(0, ${height - padding.bottom})`)
      .call(axisBottom(x).tickSizeOuter(0).tickFormat((d, i) => i === keys(props.data).length - 1 ? "$200+" : d.split("_").slice(1).map((i) => `$${i}k`).join("-")))
      .selectAll("text")
      .attr("transform", "translate(-10,0)rotate(-45)")
      .style("text-anchor", "end")
  }
}

onMounted(drawbarChart);

watch(() => props.data, drawbarChart, { immediate: true, deep: true });
</script>

<style>
@import "@/assets/style/main.css";
</style>