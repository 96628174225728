<template>
  <div>
    <div class="pb-1 font-medium text-lg">{{ title }}</div>
    <div class="h-96 cursor-default" ref="barChart"></div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { keys, isEmpty, values, max } from "lodash";
import { scaleLinear, scaleBand } from "d3-scale";
import { select } from "d3-selection";
import { axisLeft } from "d3-axis";
import "d3-transition";

const props = defineProps({
  title: String,
  data: {
    type: Object,
    default: () => {}
  }
});

const barChart = ref(null);

async function drawbarChart() {
  if (!isEmpty(props.data)) {
    const width = barChart.value.clientWidth;
    const height = barChart.value.clientHeight;
    const padding = { top: 0, right: 60, bottom: 40, left: 100 };

    const data = props.data.reduce((d, { duration, percentage }) => {
      const range = +duration;
      if (range < 2) {
        d.one += +percentage;
      } else if (range < 3) {
        d.two += +percentage;
      } else if (range < 4) {
        d.three += +percentage;
      } else if (range < 5) {
        d.four += +percentage;
      } else {
        d.fivePlus += +percentage;
      }
      return d;
    }, { one: 0, two: 0, three: 0, four: 0, fivePlus: 0 });

    const categories = keys(data);
    const x = scaleLinear()
      .domain([0, max(values(data))])
      .range([padding.left, width - padding.right]);
    const y = scaleBand()
      .domain(categories)
      .range([height - padding.bottom, padding.top])
      .padding(0.1);

    // create container
    select(barChart.value).selectChildren("*").remove();
    const svg = select(barChart.value).append("svg")
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [0, 0, width, height]);

    // bars
    svg.selectAll("bars").data(Object.entries(data)).enter().append("rect")
      .attr("x", x(0))
      .attr("y", (d) => y(d[0]))
      .attr("width", (d) => x(d[1]) - x(0))
      .attr("height", y.bandwidth())
      .attr("fill", "#C1A390");

    const labels = ["15min - 2hr", "2hr - 3hr", "3hr - 4hr", "4hr - 5hr", "5hr+"];
    svg.append("g")
      .attr("transform", `translate(${padding.left}, 0)`)
      .call(axisLeft(y).tickPadding(4).tickFormat((t, i) => labels[i]))
      .call((g) => g.select(".domain").remove())
      .call((g) => g.selectAll(".tick line").remove())
      .call((g) => g.selectAll(".tick")
        .attr("fill", "#E5E7EB")
        .attr("font-size", 16)
      );
  }
}

onMounted(drawbarChart);

watch(() => props.data, drawbarChart, { immediate: true, deep: true });
</script>

<style>
@import "@/assets/style/main.css";
</style>