import { defineStore } from "pinia";

export const useStudyStore = defineStore({
  id: "study",
  state: () => ({
    study: {
      name: "",
      status: ""
    },
    activeOrg: {},
    config: {},
    zones: [],
    artifacts: {
      PO: {},
      POS: {},
      LA: {},
      LAS: {},
      DF: {},
      ZF: {},
      CF: {}
    }
  }),
  actions: {
    reset() {
      this.study = {};
      this.config = {};
      this.zones = [];
      this.artifacts = {
        PO: {},
        POS: {},
        LA: {},
        LAS: {},
        DF: {},
        ZF: {},
        CF: {}
      };
    }
  }
});
