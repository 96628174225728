<template>
  <header class="px-8 py-4 w-full flex justify-between">
    <h2 class="text-3xl dark:text-zinc-50">Studies</h2>
    <CreateStudyDialog v-if="canCreateStudies" />
  </header>
  <section class="h-[calc(100%-68px)]">
    <div class="px-8 py-4 grid grid-cols-4 gap-x-4 items-center">
      <OrganizationSelect v-model="activeOrg" />
      <SearchInput v-model="studyFilter" />
    </div>
    <div class="h-[calc(100%-70px)] px-8">
      <div class="flex h-full w-full place-items-center justify-center" v-if="pending">
        <IconSpinner class="animate-spin w-12 h-12" />
      </div>
      <ul class="rounded overflow-hidden divide-zinc-200 divide-y dark:divide-zinc-600" v-else>
        <StudyListItem v-for="study in filteredStudies" :study="study" :key="study.id" />
      </ul>
      <div
        class="hover:bg-zinc-300 hover:dark:bg-zinc-800 p-4 w-full text-center text-lg"
        v-if="!userStore.organizations.length">
        You do not belong to any organizations, contact AirSage for more information.
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, computed, watch, onBeforeMount } from "vue";
import { useAuth0 } from "@auth0/auth0-vue";
import { storeToRefs } from "pinia";
import { isEmpty, first, get } from "lodash";
import OrganizationSelect from "@/components/OrganizationSelect.vue";
import IconSpinner from "@/components/icons/IconSpinner.vue";
import SearchInput from "@/components/SearchInput.vue";
import StudyListItem from "@/components/StudyListItem.vue";
import CreateStudyDialog from "@/components/CreateStudyDialog.vue";
import { OrganizationsService } from "@/services";
import { useUserStore } from "@/stores/user";
import { useStudyStore } from "@/stores/study";

const userStore = useUserStore();
const studyStore = useStudyStore();

const { getAccessTokenSilently } = useAuth0();

const pending = ref(false);
const studies = ref([]);
const studyFilter = ref("");
const { activeOrg } = storeToRefs(studyStore);
const filteredStudies = computed(() => studies.value.filter(({ name }) => isEmpty(studyFilter.value) || name.toLowerCase().includes(studyFilter.value.toLowerCase())));
const canCreateStudies = computed(() => userStore.isAdmin || (!isEmpty(activeOrg.value) && Object.values(get(activeOrg.value, "modules", {})).some(({ write }) => write)));

const loadStudies = async () => {
  if (activeOrg.value) {
    try {
      pending.value = true;
      const token = await getAccessTokenSilently();
      studies.value = await OrganizationsService.listStudies(activeOrg.value.id, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
    } catch (error) {
      console.error(error);
    } finally {
      pending.value = false;
    }
  }
};

watch(activeOrg, loadStudies);
onBeforeMount(() => {
  activeOrg.value = isEmpty(activeOrg.value) ? first(userStore.organizations) : activeOrg.value;
  loadStudies();
});
</script>
