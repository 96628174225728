import { Map } from "maplibre-gl";
import { bbox } from "@turf/turf";
import { isArray } from "lodash";


class AirSageMap extends Map {
  constructor(container, {
    style = "https://api.maptiler.com/maps/daf8b0da-58d2-4f37-9563-1f59182f279b/style.json?key=Pt2TXgCtJ5ziGcCMTIfn",
    darkStyle = "https://api.maptiler.com/maps/aa81da81-074e-4a48-9af9-066594208283/style.json?key=Pt2TXgCtJ5ziGcCMTIfn",
    preserveDrawingBuffer = false,
    eventHandlers = {},
    minZoom = 2,
    maxZoom = 18
  } = {}) {
    super({
      container,
      center: [-90.5794797, 39.8283459],
      zoom: 3.39,
      interactive: true,
      attributionControl: false,
      preserveDrawingBuffer,
      style: window.matchMedia("(prefers-color-scheme: dark)").matches ? darkStyle : style,
      minZoom,
      maxZoom
    });

    Object.entries(eventHandlers).forEach((e) => {
      const [event, handler] = e;
      this.on(event, handler);
    });
  }

  centerOn(feature, { padding = 15, easing } = {}) {
    const options = {
      padding
    };
    if (easing) {
      options.easing = easing;
    }
    if (isArray(feature)) {
      this.fitBounds(feature, options);
    } else {
      this.fitBounds(bbox(feature), options);
    }
  }
}

export default AirSageMap;
