import { merge } from "lodash";
import { featureCollection } from "@turf/turf";

import Service from "@/services/service";

export default class StudyService extends Service {
  constructor() {
    super("studies");
  }

  read(id, options) {
    return this.get(`/${id}`, options);
  }

  create(info, options) {
    const { name, description, type, zones: features, orgId, planId, ...config } = info;
    const study = { name, description, type };
    const zones = featureCollection(features);
    config.type = type;
    return this.post("", { study, zones, config, orgId, planId }, options);
  }

  update(id, study, options) {
    return this.patch(`/${id}`, study, options);
  }

  getConfig(id, options) {
    return this.get(`/${id}/config`, options);
  }

  getZones(id, options) {
    return this.get(`/${id}/zones`, options);
  }

  listArtifacts(id, options) {
    return this.get(`/${id}/artifacts`, options);
  }

  uploadArtifact(id, formData, options) {
    options = merge(options, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return this.post(`/${id}/artifacts`, formData, options);
  }

  downloadArtifact(id, aid, options) {
    return this.get(`/${id}/artifacts/${aid}/download`, options);
  }

  getMaterializedView(id, options) {
    return this.get(`/${id}/materialized-view`, options);
  }
}
