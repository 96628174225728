<template>
  <header class="px-8 py-4 w-full flex justify-between">
    <h2 class="text-3xl dark:text-zinc-50">Plans</h2>
  </header>
  <section class="h-[calc(100%-68px)]">
    <div class="px-8 py-4 grid grid-cols-4 gap-x-4 items-center">
      <OrganizationSelect v-model="activeOrg" />
      <SearchInput v-model="planFilter" />
    </div>
    <div class="h-[calc(100%-70px)] px-8">
      <div class="flex h-full w-full place-items-center justify-center" v-if="pending">
        <IconSpinner class="animate-spin w-12 h-12" />
      </div>
      <ul class="rounded overflow-hidden divide-zinc-200 divide-y dark:divide-zinc-600" v-else>
        <PlanListItem v-for="plan in filteredPlans" :plan="plan" :key="plan.id" />
      </ul>
      <div
        class="hover:bg-zinc-300 hover:dark:bg-zinc-800 p-4 w-full text-center text-lg"
        v-if="!userStore.organizations.length">
        You do not belong to any organizations, contact AirSage for more information.
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, computed, watch, onBeforeMount } from "vue";
import { useAuth0 } from "@auth0/auth0-vue";
import { storeToRefs } from "pinia";
import { isEmpty, first } from "lodash";
import OrganizationSelect from "@/components/OrganizationSelect.vue";
import IconSpinner from "@/components/icons/IconSpinner.vue";
import SearchInput from "@/components/SearchInput.vue";
import PlanListItem from "@/components/PlanListItem.vue";
import { OrganizationsService } from "@/services";
import { useUserStore } from "@/stores/user";
import { useStudyStore } from "@/stores/study";

const userStore = useUserStore();
const studyStore = useStudyStore();

const { getAccessTokenSilently } = useAuth0();

const pending = ref(false);
const plans = ref([]);
const planFilter = ref("");
const { activeOrg } = storeToRefs(studyStore);
const filteredPlans = computed(() => plans.value.filter(({ name }) => isEmpty(planFilter.value) || name.toLowerCase().includes(planFilter.value.toLowerCase())));

const loadPlans = async () => {
  if (activeOrg.value) {
    try {
      pending.value = true;
      const token = await getAccessTokenSilently();
      plans.value = await OrganizationsService.listPlans(activeOrg.value.id, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
    } catch (error) {
      console.error(error);
    } finally {
      pending.value = false;
    }
  }
};

watch(activeOrg, loadPlans);
onBeforeMount(() => {
  activeOrg.value = isEmpty(activeOrg.value) ? first(userStore.organizations) : activeOrg.value;
  loadPlans();
});
</script>
