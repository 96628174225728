<template>
  <div>
    <div class="pb-1 font-medium text-lg">{{ title }}</div>
    <div class="h-96 cursor-default" ref="barChart"></div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { isEmpty } from "lodash";
import { max } from "simple-statistics";
import { format, parse } from "date-fns";
import { scaleLinear, scaleBand } from "d3-scale";
import { select } from "d3-selection";
import { axisBottom, axisLeft } from "d3-axis";
import "d3-transition";
import { mk } from "../../../lib/utils";

const props = defineProps({
  title: String,
  data: {
    type: Object,
    default: () => {}
  }
});

const barChart = ref(null);

async function drawbarChart() {
  if (!isEmpty(props.data)) {
    const width = barChart.value.clientWidth;
    const height = barChart.value.clientHeight;
    const padding = { top: 25, right: 0, bottom: 60, left: 40 };

    const data = props.data.map((d) => {
      return {
        date: parse(d.date, "yyyyMMdd", new Date()),
        count: +d.count
      };
    }).sort(((a, b) => a.date - b.date));

    const x = scaleBand()
      .domain(data.map(((d) => d.date)))
      .range([padding.left, width - padding.right])
      .padding(0.1);
    const y = scaleLinear()
      .domain([0, max(data.map((d) => d.count))]).nice()
      .range([height - padding.bottom, padding.top]);

    // create container
    select(barChart.value).selectChildren("*").remove();
    const svg = select(barChart.value).append("svg")
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [0, 0, width, height]);

    // bars
    svg.append("g").selectAll().data(data).enter().append("g")
      .append("rect")
      .attr("x", (d) => x(d.date))
      .attr("y", (d) => y(d.count))
      .attr("width", x.bandwidth())
      .attr("height", (d) => y(0) - y(d.count))
      .attr("fill", "#2171B5");

    svg.append("g")
      .attr("transform", `translate(0, ${height - padding.bottom})`)
      .call(axisBottom(x).tickSizeOuter(0).tickFormat((d) => format(d, "MMM dd, yyyy")))
      .selectAll("text")
      .attr("transform", "translate(-10,0)rotate(-45)")
      .style("text-anchor", "end");

    svg.append("g")
      .attr("transform", `translate(${padding.left}, 0)`)
      .call(axisLeft(y).tickSizeOuter(0).tickFormat((d) => mk(d)))
      .selectAll("text")
      .style("text-anchor", "end");
  }
}

onMounted(drawbarChart);

watch(() => props.data, drawbarChart, { immediate: true, deep: true });
</script>

<style>
@import "@/assets/style/main.css";
</style>