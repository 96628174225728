import { defineStore } from "pinia";

export const useCreateStore = defineStore({
  id: "create",
  state: () => ({
    plan: {}
  }),
  actions: {
    reset() {
      this.plan = {};
    }
  }
});
