<template>
  <div class="h-[calc(100vh-178px)] flex gap-x-4">
    <ContentBlock class="basis-60" title="Filters">
      <div class="p-4 flex flex-col gap-y-4">
        <SingleObjectSelect label="Month" :options="monthOptions" v-model="proxyFilter.month" inline />
        <div
          class="mt-8 flex text-zinc-50 space-x-2 items-center justify-center px-3 py-2 rounded-lg border border-accent bg-accent/75 hover:bg-accent text-sm cursor-pointer"
          @click="applyFilter">
          <span>Apply</span>
        </div>
      </div>
    </ContentBlock>
    <div class="grow rounded-lg overflow-hidden h-full relative" ref="mapElement">
      <!-- <div class="absolute bottom-4 right-4 w-24 bg-zinc-100 dark:bg-zinc-800 rounded-md shadow">
        <div class="rounded-t-md bg-zinc-200 dark:bg-zinc-700 p-2 flex justify-between items-center">
          <h5 class="font-medium text-sm">Legend</h5>
        </div>
        <div class="p-2">
          <ul>
            <li class="flex items-center justify-between" v-for="(threshold, color) in legendColors" :key="color">
              <div class="h-3.5 w-3.5 rounded-full" :style="`background-color: ${color}`"></div>
              <div>{{ threshold }}+</div>
            </li>
          </ul>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, onBeforeUnmount } from "vue";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import { useAuth0 } from "@auth0/auth0-vue";
import { format } from "date-fns";
import { cloneDeep, first, isEmpty } from "lodash";
import AirSageMap from "@/lib/map";
import { unfurlNestedDates } from "@/lib/utils";
import SingleObjectSelect from "@/components/SingleObjectSelect.vue";
import ContentBlock from "@/components/ContentBlock.vue";
import { StudiesService } from "@/services";
import { useStudyStore } from "@/stores/study";
import { useAlertStore } from "@/stores/alert";

const alertStore = useAlertStore();
const studyStore = useStudyStore();
const route = useRoute();
const { getAccessTokenSilently } = useAuth0();
const { zones, config } = storeToRefs(studyStore);
let data;
const monthOptions = ref([]);
const mounted = ref(false);
const proxyFilter = ref({
  month: ""
});
const filter = ref({
  month: ""
});

onMounted(async () => {
  data = await readMaterializedView("materialized_data");
  mounted.value = true;
});

onBeforeUnmount(studyStore.reset);

const POINT_SOURCE_ID = "pointsSource";
const POINT_LAYER_ID = "pointsLayer";
const STUDY_AREA_SOURCE_ID = "studyArea";
const STUDY_AREA_SHAPE_ID = "studyAreaShape";
const STUDY_AREA_LINE_ID = "studyAreaLine";
let map = null;

function applyFilter() {
  filter.value = cloneDeep(proxyFilter.value);
  loadLayers();
}

const unwatch = watch([mounted, zones, config], () => {
  if (mounted.value && !isEmpty(zones.value) && !isEmpty(config.value)) {
    monthOptions.value = unfurlNestedDates(config.value.dates || []).map((m) => ({
      name: format(m, "MMM yyyy"),
      value: format(m, "yyyyMM")
    }));
    const firstMonth = first(monthOptions.value).value;

    proxyFilter.value = {
      month: firstMonth
    };

    filter.value = cloneDeep(proxyFilter.value);
    loadMap();
  }
});

async function readMaterializedView(view) {
  try {
    const { id } = route.params;
    const token = await getAccessTokenSilently();
    return await StudiesService.getMaterializedView(id, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: { view }
    });
  } catch (error) {
    alertStore.alertError(error);
  }
}

function loadLayers() {
  const { id } = route.params;
  if (map) {
    [POINT_LAYER_ID, STUDY_AREA_LINE_ID, STUDY_AREA_SHAPE_ID].forEach((id) => {
      if (map.getLayer(id)) {
        map.removeLayer(id);
      }
    });

    if (map.getSource(POINT_SOURCE_ID)) {
      map.removeSource(POINT_SOURCE_ID);
    }

    map.addSource(POINT_SOURCE_ID, {
      type: "vector",
      tiles: [
        `${import.meta.env.VITE_MARTIN_URL}/get_activity_density/{z}/{x}/{y}?${new URLSearchParams([
          ...Object.entries({
            id,
            month: filter.value.month
          })
        ]).toString()}`
      ]
    });

    map.addLayer({
      id: POINT_LAYER_ID,
      type: "heatmap",
      source: POINT_SOURCE_ID,
      "source-layer": "activity-density"
    });

    if (!map.getSource(STUDY_AREA_SOURCE_ID)) {
      map.addSource(STUDY_AREA_SOURCE_ID, {
        type: "geojson",
        data: zones.value
      });
    }

    map.addLayer({
      id: STUDY_AREA_SHAPE_ID,
      type: "fill",
      source: STUDY_AREA_SOURCE_ID,
      paint: {
        "fill-color": "#2171B5",
        "fill-opacity": ["case", ["boolean", ["feature-state", "hover"], false], 0.92, 0.025]
      }
    });

    map.addLayer({
      id: STUDY_AREA_LINE_ID,
      type: "line",
      source: STUDY_AREA_SOURCE_ID,
      paint: {
        "line-color": "#2171B5",
        "line-width": 1,
        "line-opacity": ["case", ["boolean", ["feature-state", "hover"], false], 0.75, 0.25]
      }
    });
  }
}

const mapElement = ref(null);
function loadMap() {
  map = new AirSageMap(mapElement.value, {
    eventHandlers: {
      "style.load": loadLayers
    }
  });
  map.centerOn(zones.value.bbox || zones.value, { easing: () => 1 });
  unwatch();
}
</script>