import Service from "@/services/service";

export default class UserService extends Service {
  constructor() {
    super("users");
  }

  read(id, options) {
    return this.get(`/${id}`, options);
  }

  listOrganizations(id, options) {
    return this.get(`/${id}/organizations`, options);
  }
}
