// TODO: update to using mande
// import { mande } from "mande";
import axios from "axios";
import querystring from "query-string";

export default class Service {
  constructor(resource) {
    this.service = axios.create({
      baseURL: `${import.meta.env.VITE_API_URL}/${resource}`,
      headers: {
        Accept: "application/json"
      },
      paramsSerializer(params) {
        return querystring.stringify(params, { arrayFormat: "none" });
      }
    });
  }

  async get(url = "", options = {}) {
    const { data } = await this.service.get(url, options);
    return data;
  }

  async post(url = "", body = {}, options = {}) {
    const { data } = await this.service.post(url, body, options);
    return data;
  }

  async patch(url = "", body = {}, options = {}) {
    const { data } = await this.service.patch(url, body, options);
    return data;
  }

  async delete(url = "", options = {}) {
    const { data } = await this.service.delete(url, options);
    return data;
  }
}
