<template>
  <div
    class="flex text-zinc-50 space-x-2 items-center px-3 py-2 rounded-lg border border-accent bg-accent/75 hover:bg-accent text-sm cursor-pointer"
    @click="openModal">
    <IconStudiesPlus class="w-5 h-5" />
    <span>Create Study</span>
  </div>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-10">
      <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100"
        leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-black bg-opacity-50" />
      </TransitionChild>
      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95">
            <DialogPanel
              class="w-full transform overflow-hidden rounded-lg bg-zinc-100 dark:bg-zinc-800 rounded-lg text-zinc-700 text-left dark:text-zinc-200 shadow-xl transition-all dark:divide-zinc-600"
              :class="[step === 0 ? 'max-w-md' : 'max-w-2xl']"
              >
              <DialogTitle as="h3" class="p-4 text-lg font-medium leading-6">{{ dialogTitle }}</DialogTitle>
              <ChoosePlan @plan="planSelected" v-if="step === 0"/>
              <ChooseStudyType v-else/>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from "@headlessui/vue";
import IconStudiesPlus from "@/components/icons/IconStudiesPlus.vue";
import ChoosePlan from "@/components/ChoosePlan.vue";
import ChooseStudyType from "@/components/ChooseStudyType.vue";
import { useCreateStore } from "@/stores/create";


defineProps({
  title: String
});

const isOpen = ref(false);

const closeModal = () => {
  isOpen.value = false;
  step.value = 0;
  createStore.reset();
};

const openModal = () => {
  isOpen.value = true;
};

const step = ref(0);
const dialogTitle = computed(() => step.value === 0 ? "Choose Plan" : "Choose Study Type");

const createStore = useCreateStore();
const { plan } = storeToRefs(createStore);
const planSelected = (p) => {
  plan.value = p;
  step.value = 1;
}
</script>
