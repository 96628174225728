import { createAuth0 } from "@auth0/auth0-vue";
import { createApp } from "vue";
import { createPinia } from "pinia";
import * as Sentry from "@sentry/vue";
import { version } from "../package.json";

import App from "@/App.vue";
import router from "@/router";

import VisualizeTargetedLocationAnalysis from "@/components/visualizations/TargetedLocationAnalysis.vue";
import VisualizeActivityDensityAnalysis from "@/components/visualizations/ActivityDensity.vue";
import VisualizePending from "@/components/visualizations/PendingType.vue";


const app = createApp(App);
app.use(createPinia());
app.use(router);
app.use(createAuth0({
  domain: import.meta.env.VITE_AUTH0_DOMAIN,
  clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
  authorizationParams: {
    audience: import.meta.env.VITE_AUTH0_AUDIENCE,
    redirect_uri: `${window.location.origin}/callback`
  }
}));
app.component("visualize-tla", VisualizeTargetedLocationAnalysis);
app.component("visualize-ad", VisualizeActivityDensityAnalysis);
app.component("visualize-pending", VisualizePending);

const sentryDsn = import.meta.env.VITE_SENTRY_DSN;
if (sentryDsn) {
  const environment = import.meta.env.VITE_API_URL.slice(-3) === "com" ? "production" : "development";
  Sentry.init({
    app,
    dsn: sentryDsn,
    debug: environment !== "production",
    environment,
    release: version,
    integrations: [],
    ignoreUrls: [
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extensions:\/\//i
    ]
  });
}

app.mount("#app");
