<template>
  <template v-if="loaded">
    <div class="h-[calc(100vh-178px)] flex gap-x-4">
      <ContentBlock class="basis-60" title="Filters">
        <div class="p-4 flex flex-col gap-y-4">
          <SingleObjectSelect label="POI" :options="poiOptions" v-model="proxyFilter.poi" inline />
          <SingleObjectSelect label="Month" :options="monthOptions" v-model="proxyFilter.month" inline />
          <SingleObjectSelect label="Level" :options="levelOptions" v-model="proxyFilter.level" inline />
          <div
            class="mt-8 flex text-zinc-50 space-x-2 items-center justify-center px-3 py-2 rounded-lg border border-accent bg-accent/75 hover:bg-accent text-sm cursor-pointer"
            @click="applyFilter">
            <span>Apply</span>
          </div>
        </div>
      </ContentBlock>
      <div class="grow rounded-lg overflow-hidden h-full relative" ref="mapElement">
        <div class="absolute bottom-4 right-4 z-10 w-24 bg-zinc-100 dark:bg-zinc-800 rounded-md shadow">
          <div class="rounded-t-md bg-zinc-200 dark:bg-zinc-700 p-2 flex justify-between items-center">
            <h5 class="font-medium text-sm">Legend</h5>
          </div>
          <div class="p-2">
            <ul>
              <li class="flex items-center justify-between" v-for="(threshold, color) in legendColors" :key="color">
                <div class="h-3.5 w-3.5 rounded-full" :style="`background-color: ${color}`"></div>
                <div>{{ threshold }}+</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div>
      <ContentBlock class="basis-60">
        <div class="p-2 grid grid-cols-6 gap-4">
          <div class="col-span-6">
            <DailyVisitationChart title="Daily Visitation Pattern" :data="dailyChartData" />
          </div>
          <div class="col-span-3">
            <HourlyHeatmap title="Hourly Visitation Heatmap" :data="hourlyCountsChartData" />
          </div>
          <div class="col-span-3">
            <VisitDurationChart title="Visit Duration" :data="vistDurationChartData" />
          </div>
          <div class="col-span-2">
            <GenderAgeChart title="Gender & Age" :data="genderAgeChartData" />
          </div>
          <div class="col-span-2">
            <PieChart title="Ethnicity" :data="ethnicityChartData" />
          </div>
          <div class="col-span-2">
            <IncomeChart title="Income" :data="incomeChartData" />
          </div>
        </div>
      </ContentBlock>
    </div>
  </template>
  <div class="h-[calc(100vh-178px)] flex place-items-center justify-center" v-else>
    <IconSpinner class="animate-spin w-12 h-12" />
  </div>
</template>

<script setup>
import { ref, watch, onMounted, onBeforeUnmount, nextTick } from "vue";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import { useAuth0 } from "@auth0/auth0-vue";
import { format } from "date-fns";
import { cloneDeep, first, isEmpty } from "lodash";
import { min } from "simple-statistics";
import { scaleThreshold } from "d3-scale";
import { schemeBlues } from "d3-scale-chromatic";
import { Popup } from "maplibre-gl";
import AirSageMap from "@/lib/map";
import { unfurlNestedDates, mk } from "@/lib/utils";
import SingleObjectSelect from "@/components/SingleObjectSelect.vue";
import ContentBlock from "@/components/ContentBlock.vue";
import IconSpinner from "@/components/icons/IconSpinner.vue";
import PieChart from "@/components/visualizations/charts/PieChart.vue";
import GenderAgeChart from "@/components/visualizations/charts/GenderAgeChart.vue";
import IncomeChart from "@/components/visualizations/charts/IncomeChart.vue";
import HourlyHeatmap from "@/components/visualizations/charts/HourlyHeatmap.vue";
import VisitDurationChart from "@/components/visualizations/charts/VisitDurationChart.vue";
import DailyVisitationChart from "@/components/visualizations/charts/DailyVisitationChart.vue";
import { StudiesService } from "@/services";
import { useStudyStore } from "@/stores/study";
import { useAlertStore } from "@/stores/alert";

const alertStore = useAlertStore();
const studyStore = useStudyStore();
const route = useRoute();
const { getAccessTokenSilently } = useAuth0();
const { zones, config } = storeToRefs(studyStore);
const loaded = ref(false);
const poiOptions = ref([]);
const monthOptions = ref([]);
const levelOptions = ref([{
  name: "States",
  value: "state"
}, {
  name: "Counties",
  value: "county"
}, /* {
  name: "Tracts",
  value: "tract"
}, {
  name: "Block Groups",
  value: "blockgroup"
} ,*/ {
  name: "MSA",
  value: "msa"
}]);
const mounted = ref(false);
const proxyFilter = ref({
  poi: "",
  month: "",
  level: ""
});
const filter = ref({
  poi: "",
  month: "",
  level: ""
});

onMounted(async () => {
  mounted.value = true;
});

onBeforeUnmount(studyStore.reset);

const ZONE_SHAPE_LAYER_ID = "zoneShapes";
const ZONE_LINE_LAYER_ID = "zoneLines";
const ZONE_SOURCE_ID = "zones";
const SELECT_LINK_SOURCE_ID = "selectLinkZones";
const SELECT_LINK_ZONE_SHAPE_ID = "selectLinkZoneShape";
const SELECT_LINK_ZONE_LINE_ID = "selectLinkZoneLine";
let map = null;
let hoverStateId;
let thresholds = [];
let dailyCounts = [];
let hourlyCounts = [];
let visitDuration = [];
let ethnicity = [];
let ageGender = [];
let income = [];

async function applyFilter() {
  filter.value = cloneDeep(proxyFilter.value);
  setChartData();
  loadLayers();
}

const unwatch = watch([mounted, zones, config], async () => {
  if (mounted.value && !isEmpty(zones.value) && !isEmpty(config.value)) {
      ({
        thresholds,
        dailyCounts,
        hourlyCounts,
        visitDuration,
        ethnicity,
        ageGender,
        income
      } = await readMaterializedView("materialized_data"));

    poiOptions.value = zones.value.features.map(({ properties }) => {
      return {
        name: properties[config.value.zonePropertyMap.name],
        value: properties[config.value.zonePropertyMap.id]
      };
    });
    const firstPoi = first(poiOptions.value).value;

    monthOptions.value = unfurlNestedDates(config.value.dates || []).map((m) => ({
      name: format(m, "MMM yyyy"),
      value: format(m, "yyyyMM")
    }));
    const firstMonth = first(monthOptions.value).value;

    proxyFilter.value = {
      poi: firstPoi,
      month: firstMonth,
      level: "state"
    };

    filter.value = cloneDeep(proxyFilter.value);
    loaded.value = true;
    await nextTick();

    loadMap();
  }
});

async function readMaterializedView(view) {
  try {
    const { id } = route.params;
    const token = await getAccessTokenSilently();
    return await StudiesService.getMaterializedView(id, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: { view }
    });
  } catch (error) {
    alertStore.alertError(error);
  }
}

const legendColors = ref([]);
function generateExpression(thresholds, tileKey) {
  const arrayToObject = (array) => {
    const legend = {};
    array.unshift(0);
    while (array.length) {
      const color = array.shift();
      const threshold = array.shift();
      legend[threshold] = color;
    }
    return legend;
  };
  const numCategories = min([thresholds.length, 8]);
  const colors = schemeBlues[numCategories + 1];
  const scale = scaleThreshold(thresholds, colors);
  let expression = [];
  expression.push(scale(0))
  scale.domain().forEach((d) => {
    expression.push(d, scale(d));
  });
  expression = ["step", ["to-number", ["get", tileKey]]].concat(expression);
  legendColors.value = arrayToObject(expression.slice(2));
  return expression;
}

const popup = new Popup({
  closeButton: false,
  closeOnClick: false
});

function loadLayers() {
  const { id } = route.params;
  if (map) {
    [ZONE_SHAPE_LAYER_ID, ZONE_LINE_LAYER_ID, SELECT_LINK_ZONE_SHAPE_ID, SELECT_LINK_ZONE_LINE_ID].forEach((id) => {
      if (map.getLayer(id)) {
        map.removeLayer(id);
      }
    });

    if (map.getSource(ZONE_SOURCE_ID)) {
      map.removeSource(ZONE_SOURCE_ID);
    }

    if (!map.getSource(SELECT_LINK_SOURCE_ID)) {
      map.addSource(SELECT_LINK_SOURCE_ID, {
        type: "geojson",
        data: zones.value,
        promoteId: config.value.zonePropertyMap.id
      });
    }

    map.addSource(ZONE_SOURCE_ID, {
      type: "vector",
      tiles: [
        `${import.meta.env.VITE_MARTIN_URL}/get_tla_${filter.value.level}/{z}/{x}/{y}?${new URLSearchParams([
          ...Object.entries({
            sid: id,
            pid: filter.value.poi,
            month: filter.value.month
          })
        ]).toString()}`
      ],
      promoteId: filter.value.level === "msa" ? "geoid" : "fips",
      minzoom: 0,
      maxzoom: 8
    });

    const expression = generateExpression(thresholds, "count");
    map.addLayer({
      id: ZONE_SHAPE_LAYER_ID,
      type: "fill",
      source: ZONE_SOURCE_ID,
      "source-layer": "tla",
      paint: {
        "fill-color": expression,
        "fill-opacity": ["case", ["boolean", ["feature-state", "hover"], false], 0.92, 0.67]
      }
    });

    map.on("click", ZONE_SHAPE_LAYER_ID, (e) => {
      const [feature] = e.features;
      if (feature) {
        const { id, properties } = feature;
        if (id) {
          popup.setLngLat(e.lngLat).setHTML(`
            <div class="px-2">
              <div class="text-lg">${properties.name}${filter.value.level === "county" ? `, ${properties.statecode}` : ""}</div>
              <div>
                <span class="text-md font-bold">${mk(properties.count)}</span>
                <span class="text-sm text-zinc-400">estimated vistors</span>
              </div>
            </div>
          `).addTo(map);
        }
      }
    });

    map.on("mousemove", ZONE_SHAPE_LAYER_ID, (e) => {
      const [feature] = e.features;
      if (feature) {
        const { id } = feature;
        if (id) {
          map.getCanvas().style.cursor = "pointer";

          if (hoverStateId !== id) {
            if (hoverStateId) {
              map.setFeatureState({
                source: ZONE_SOURCE_ID,
                sourceLayer: "tla",
                id: hoverStateId
              }, { hover: false });
            }

            hoverStateId = id;
            map.setFeatureState({
              source: ZONE_SOURCE_ID,
              sourceLayer: "tla",
              id: hoverStateId
            }, { hover: true });
          }
        }
      }
    });

    map.on("mouseleave", ZONE_SHAPE_LAYER_ID, () => {
      map.getCanvas().style.cursor = "";
      popup.remove();
      if (hoverStateId) {
        map.setFeatureState({
          source: ZONE_SOURCE_ID,
          sourceLayer: "tla",
          id: hoverStateId
        }, { hover: false });
      }
      hoverStateId = null;
    });

    map.addLayer({
      id: SELECT_LINK_ZONE_SHAPE_ID,
      type: "fill",
      source: SELECT_LINK_SOURCE_ID,
      paint: {
        "fill-color": "#F86624",
        "fill-opacity": ["case", ["boolean", ["feature-state", "hover"], false], 0.92, 0.67]
      }
    });

    map.addLayer({
      id: SELECT_LINK_ZONE_LINE_ID,
      type: "line",
      source: SELECT_LINK_SOURCE_ID,
      paint: {
        "line-color": "#F86624",
        "line-width": 1,
        "line-opacity": ["case", ["boolean", ["feature-state", "hover"], false], 0.75, 0.25]
      }
    });

    map.setFilter(SELECT_LINK_ZONE_SHAPE_ID, ["==", config.value.zonePropertyMap.id, filter.value.poi]);
    map.setFilter(SELECT_LINK_ZONE_LINE_ID, ["==", config.value.zonePropertyMap.id, filter.value.poi]);

    // map.centerOn(zones.value.features.find((z) => z.properties[config.value.zonePropertyMap.id] === filter.value.poi), { easing: () => 1, padding: 250 });
  }
}

const mapElement = ref(null);
function loadMap() {
  map = new AirSageMap(mapElement.value, {
    eventHandlers: {
      "style.load": applyFilter
    }
  });
  // map.centerOn(zones.value, { easing: () => 1, padding: 250 });
  unwatch();
}

const dailyChartData = ref({});
const incomeChartData = ref({});
const ethnicityChartData = ref({});
const genderAgeChartData = ref({});
const hourlyCountsChartData = ref({});
const vistDurationChartData = ref({});

function setChartData() {
  if (!isEmpty(dailyCounts)) {
    dailyChartData.value = dailyCounts[`p${filter.value.poi}`][`m${filter.value.month}`]
  }

  if (!isEmpty(income)) {
    incomeChartData.value = income[`p${filter.value.poi}`][`m${filter.value.month}`];
  }

  if (!isEmpty(ethnicity)) {
    ethnicityChartData.value = ethnicity[`p${filter.value.poi}`][`m${filter.value.month}`];
  }

  if (!isEmpty(ageGender)) {
    genderAgeChartData.value = ageGender[`p${filter.value.poi}`][`m${filter.value.month}`];
  }

  if (!isEmpty(hourlyCounts)) {
    hourlyCountsChartData.value = hourlyCounts[`p${filter.value.poi}`][`m${filter.value.month}`];
  }

  if (!isEmpty(visitDuration)) {
    vistDurationChartData.value = visitDuration[`p${filter.value.poi}`][`m${filter.value.month}`];
  }
}
</script>
