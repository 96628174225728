import Service from "@/services/service";

export default class ModuleService extends Service {
  constructor() {
    super("modules");
  }

  list(options) {
    return this.get("", options);
  }
}
