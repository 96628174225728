<template>
  <div class="p-4 grid grid-cols-2 gap-4">
    <!-- Targeted Location Analysis -->
    <div class="border border-zinc-300 dark:border-zinc-700 rounded-lg overflow-hidden flex flex-col" v-if="createStore.plan.modules.tla.write">
      <div class="p-4">
        <p>Presents footfall traffic trends for a point of interest.</p>
      </div>
      <div class="grow"></div>
      <RouterLink
        class="text-zinc-50 flex space-x-2 items-center justify-center p-2 border border-accent bg-accent/75 hover:bg-accent focus:outline-none"
        to="studies/create">Targeted Location Analysis</RouterLink>
    </div>
    <!-- Activity Density -->
    <div class="border border-zinc-300 dark:border-zinc-700 rounded-lg overflow-hidden flex flex-col" v-if="createStore.plan.modules.ad.write">
      <div class="p-4">
        <p>Presents areas with high concentrations of activity within a study area.</p>
      </div>
      <div class="grow"></div>
      <RouterLink
        class="text-zinc-50 flex space-x-2 items-center justify-center p-2 border border-accent bg-accent/75 hover:bg-accent focus:outline-none"
        to="studies/create-ad">Activity Density</RouterLink>
    </div>
  </div>
</template>

<script setup>
import { RouterLink } from "vue-router";
import { useCreateStore } from "@/stores/create";
const createStore = useCreateStore();
</script>