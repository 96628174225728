import { defineStore } from "pinia";

export const useAlertStore = defineStore({
  id: "alert",
  state: () => ({
    color: "",
    message: "",
    timeout: 6000
  }),
  actions: {
    alertError(error) {
      this.color = "error";
      this.message = error.response ? error.response.data.message : error.message || error.description || error.errorDescription || error;
    },

    alertSuccess(message) {
      this.color = "success";
      this.message = message;
    },

    reset() {
      this.color = "";
      this.message = "";
      this.timeout = 6000;
    }
  }
});
