<template>
  <div class="w-full flex items-center justify-between py-1">
    <label class="font-medium" v-if="!inline">{{ label }}: </label>
    <div class="relative">
      <Listbox v-model="value">
        <ListboxButton class="min-w-[225px] relative cursor-pointer rounded-lg bg-transparent py-2 pl-3 pr-10 text-left text-sm disabled:text-zinc-400 border border-zinc-400 disabled:border-zinc-400/50 dark:border-zinc-600 dark:disabled:border-zinc-600/50 hover:bg-zinc-50/5 focus:outline-none">
          <span class="block truncate" v-if="!isEmpty(value)">{{ selectedName }}</span>
          <span class="block" v-else>{{ placeholder }}</span>
          <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <IconChevronDown class="h-5 w-5 text-zinc-400" />
          </span>
        </ListboxButton>
        <transition leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100" leave-to-class="opacity-0">
          <ListboxOptions class="absolute mt-1 bg-zinc-100 dark:bg-zinc-800 dark:border dark:border-zinc-600 max-h-60 overflow-auto rounded-lg py-1 shadow-lg text-sm top-full inset-x-0 z-20">
            <ListboxOption v-slot="{ active, selected }" v-for="option in options" :key="option.value" :value="option.value">
              <div :class="[
                active ? 'bg-zinc-300 dark:bg-zinc-800 text-zinc-700 dark:text-zinc-200' : 'text-zinc-700 dark:text-zinc-200',
                'relative cursor-pointer select-none py-2 pl-10 pr-4'
              ]">
                <span :class="[
                  selected ? 'font-medium' : 'font-normal',
                  'block truncate'
                ]">{{ option.name }}</span>
                <span class="absolute inset-y-0 left-0 flex items-center pl-3 text-accent" v-if="selected">
                  <IconCheck class="h-5 w-5" aria-hidden="true" />
                </span>
              </div>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </Listbox>
      <label class="text-xs px-1 absolute left-2.5 -top-2/3 translate-y-full bg-zinc-50 dark:bg-zinc-400 dark:bg-zinc-800" v-if="inline">{{ label }}</label>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from "@headlessui/vue";
import { isEqual, isEmpty } from "lodash";
import IconChevronDown from "@/components/icons/IconChevronDown.vue";
import IconCheck from "@/components/icons/IconCheck.vue";

const props = defineProps({
  options: {
    type: Array,
    default: () => []
  },
  placeholder: {
    type: String,
    default: ""
  },
  label: {
    type: String,
    default: ""
  },
  inline: {
    type: Boolean,
    default: false
  },
  modelValue: {
    type: [String, Object],
    default: ""
  },
  disabled: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["update:modelValue"]);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  }
});

const selectedName = computed(() => props.options.find(o => isEqual(o.value, value.value))?.name || "");
</script>
