<template>
  <div>
    <div class="pb-1 font-medium text-lg">{{ title }}</div>
    <div class="h-96 cursor-default" ref="pieChart"></div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { keys, isEmpty, startCase, orderBy, values, sum } from "lodash";
import { scaleOrdinal } from "d3-scale";
import { schemeCategory10 } from "d3-scale-chromatic";
import { pie, arc } from "d3-shape";
import { select } from "d3-selection";
import "d3-transition";

const props = defineProps({
  title: String,
  data: {
    type: Object,
    default: () => {}
  }
});

const pieChart = ref(null);

async function drawPieChart() {
  if (!isEmpty(props.data)) {
    const darkMode = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;
    const width = pieChart.value.clientWidth;
    const height = pieChart.value.clientHeight;
    const padding = 20;
    const radius = Math.min(width, height) / 2 - padding;

    const total = sum(values(props.data).map((v) => +v));

    // create container
    select(pieChart.value).selectChildren("*").remove();
    const svg = select(pieChart.value).append("svg")
    .attr("width", width)
    .attr("height", height)
    .attr("viewBox", [0, 0, width, height])
    .append("g").attr("transform", `translate(${width / 2}, ${height / 2})`);

    const color = scaleOrdinal().domain(keys(props.data)).range(schemeCategory10);

    const thisPie = pie().value((d) => d[1]);
    const data = thisPie(Object.entries(props.data));

    const arcGenerator = arc().innerRadius(radius - 50).outerRadius(radius);

    const slices = svg.selectAll("slices").data(data).enter().append("g");
    const labels = svg.selectAll("slices").data(orderBy(data, "index")).enter().append("g");
    const valueLabels = svg.selectAll("slices").data(orderBy(data, "index")).enter().append("g");

    const mouseoverHandle = (e, d) => {
        slices.selectAll("path").filter((d2) => d.index !== d2.index).transition().duration(50).attr("opacity", 0.25);
        labels.selectAll("circle").filter((d2) => d.index !== d2.index).transition().duration(50).attr("fill-opacity", 0.25);
        labels.selectAll("text").filter((d2) => d.index !== d2.index).transition().duration(50).attr("opacity", 0.25);
        labels.selectAll("text").filter((d2) => d.index === d2.index).text(startCase(d.data[0]));
        valueLabels.selectAll("text").filter((d2) => d.index === d2.index).transition().duration(50).attr("opacity", 1)
    };

    const mouseoutHandle = (e, d) => {
        slices.selectAll("path").filter((d2) => d.index !== d2.index).transition().duration(50).attr("opacity", 1);
        labels.selectAll("circle").filter((d2) => d.index !== d2.index).transition().duration(50).attr("fill-opacity", 1);
        labels.selectAll("text").filter((d2) => d.index !== d2.index).transition().duration(50).attr("opacity", 1);
        labels.selectAll("text").filter((d2) => d.index === d2.index).text((d) => {
          if(d.data[0].length > 16) {
            return `${startCase(d.data[0]).substring(0, 16)}...`;
          }
          return startCase(d.data[0]);
        });
        valueLabels.selectAll("text").filter((d2) => d.index === d2.index).transition().duration(50).attr("opacity", 0)
    };

    slices.append("path").attr("d", arcGenerator)
      .attr("fill", (d) => color(d.data[0]))
      .attr("stroke", darkMode ? "#27272a" : "#FAFAFA")
      .style("stroke-width", 4)
      .on("mouseover", mouseoverHandle)
      .on("mouseout", mouseoutHandle);

    labels.append("text")
      .text((d) => {
        if(d.data[0].length > 16) {
          return `${startCase(d.data[0]).substring(0, 16)}...`;
        }
        return startCase(d.data[0]);
      })
      .attr("transform", (d, i) => `translate(-25, ${-(data.length / 2 * 18) + (i * 18) + 9})`)
      .style("text-anchor", "start")
      .style("fill", darkMode ? "#E5E7EB" : "#3F3F46")
      .style("font-size", 14);

    labels.append("circle")
      .attr("cx", -35)
      .attr("cy", (d, i) => -(data.length / 2 * 18) + (i * 18) + 4)
      .attr("r", 5)
      .attr("fill", (d) => color(d.data[0]));

    labels.on("mouseover", mouseoverHandle)
    .on("mouseout", mouseoutHandle);

    valueLabels.append("text")
      .text((d) => `${(d.data[1] / total * 100).toFixed(0)}%`)
      .attr("transform", (d) => `translate(${arcGenerator.centroid(d)})`)
      .style("text-anchor", "middle")
      .style("fill", "#E5E7EB")
      .style("font-size", 20)
      .attr("opacity", 0)
      .on("mouseover", mouseoverHandle)
      .on("mouseout", mouseoutHandle);
  }
}

onMounted(drawPieChart);

watch(() => props.data, drawPieChart, { immediate: true, deep: true });
</script>

<style>
@import "@/assets/style/main.css";
</style>