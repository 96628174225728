<template>
  <div class="w-full p-4">
    <div class="flex h-full w-full place-items-center justify-center" v-if="pending">
      <IconSpinner class="animate-spin size-12" />
    </div>
    <div class="mx-auto w-full" v-else>
      <RadioGroup v-model="selectedPlan" class="mb-6">
        <div class="space-y-4">
          <RadioGroupOption as="template" v-for="plan in plans" :key="plan.id" :value="plan" v-slot="{ checked }">
            <div
              :class="[checked ? 'bg-sky-900/75 text-white' : 'border border-zinc-300 dark:border-zinc-700']"
              class="relative flex cursor-pointer rounded-lg px-5 py-4 focus:outline-none"
            >
              <div class="flex w-full items-center justify-between">
                <div class="flex items-center">
                  <div class="text-sm">
                    <RadioGroupLabel as="p" :class="checked ? 'text-white' : ''" class="font-medium tracking-wide">{{ plan.name }}</RadioGroupLabel>
                    <RadioGroupDescription as="span" :class="checked ? 'text-sky-100' : 'text-zinc-500 dark:text-zinc-400'" class="inline">
                      <span>{{ plan.description[0] }} study types</span>
                      <span aria-hidden="true"> &middot; </span>
                      <span>Limit {{ plan.description[1] }} studies total</span>
                    </RadioGroupDescription>
                  </div>
                </div>
                <div v-show="checked" class="shrink-0 text-white">
                  <svg class="size-6" viewBox="0 0 24 24" fill="none">
                    <circle cx="12" cy="12" r="12" fill="#FFFFFF" fill-opacity="0.2" />
                    <path d="M7 13l3 3 7-7" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
            </div>
          </RadioGroupOption>
          <RadioGroupOption as="template" :value="defaultPlan" v-slot="{ checked }">
            <div
              :class="[checked ? 'bg-sky-900/75 text-white ' : 'border border-zinc-300 dark:border-zinc-700']"
              class="relative flex cursor-pointer rounded-lg px-5 py-4 focus:outline-none"
            >
              <div class="flex w-full items-center justify-between">
                <div class="flex items-center">
                  <div class="text-sm">
                    <RadioGroupLabel as="p" :class="checked ? 'text-white' : ''" class="font-medium tracking-wide">{{ defaultPlan.name }}</RadioGroupLabel>
                    <RadioGroupDescription as="span" :class="checked ? 'text-sky-100' : 'text-zinc-500 dark:text-zinc-400'" class="inline">
                      <span>{{ defaultPlan.description }}</span>
                    </RadioGroupDescription>
                  </div>
                </div>
                <div v-show="checked" class="shrink-0 text-white">
                  <svg class="size-6" viewBox="0 0 24 24" fill="none">
                    <circle cx="12" cy="12" r="12" fill="#FFFFFF" fill-opacity="0.2" />
                    <path d="M7 13l3 3 7-7" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
            </div>
          </RadioGroupOption>
        </div>
      </RadioGroup>
      <button class="w-full text-zinc-50 p-3 rounded-lg bg-accent/75 border border-accent hover:bg-accent text-base disabled:bg-gray-600 disabled:border-gray-600" :disabled="isEmpty(selectedPlan)" type="button" @click="selectPlan">Confirm</button>
    </div>
  </div>
</template>

<script setup>
import { ref, onBeforeMount } from "vue";
import { useAuth0 } from "@auth0/auth0-vue";
import { isEmpty } from "lodash";
import {
  RadioGroup,
  RadioGroupLabel,
  RadioGroupDescription,
  RadioGroupOption,
} from "@headlessui/vue";
import IconSpinner from "@/components/icons/IconSpinner.vue";
import { OrganizationsService } from "@/services";
import { useStudyStore } from "@/stores/study";

const emit = defineEmits(["create"]);

const { getAccessTokenSilently } = useAuth0();
const studyStore = useStudyStore();
const plans = ref([]);
const pending = ref(false);

const defaultPlan = {
  id: "default",
  name: "One Time Study",
  description: "A study outside of any plan",
  modules: {
    tla: { write: true },
    ad: { write: true }
  }
};

const loadPlans = async () => {
  if (studyStore.activeOrg.id) {
    try {
      pending.value = true;
      const token = await getAccessTokenSilently();
      plans.value = await OrganizationsService.listPlans(studyStore.activeOrg.id, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (isEmpty(plans.value)) {
        emit("plan", defaultPlan);
      }
      plans.value = plans.value.map((plan) => {
        plan.description = buildDescription(plan);
        return plan;
      });
    } catch (error) {
      console.error(error);
    } finally {
      pending.value = false;
    }
  }
};

onBeforeMount(loadPlans);

const buildDescription = (plan) => {
  let modCount = 0;
  let quotaLimit = 0;

  for (const mod in plan.modules) {
    if (plan.modules[mod].write) {
      modCount++;
      quotaLimit += plan.modules[mod].quotaLimit;
    }
  }

  return [modCount, quotaLimit];
};

const selectedPlan = ref();
const selectPlan = () => {
  emit("plan", selectedPlan.value);
}
</script>
