import { uniq, flatten, isNumber, trimEnd, mapValues } from "lodash";
import {
  eachDayOfInterval,
  eachMonthOfInterval,
  isBefore,
  parseISO,
  subDays,
  subMonths,
  isSameDay,
  isSameMonth,
  isDate,
  lastDayOfMonth
} from "date-fns";

export const mk = (value) => {
  if (!isNumber(value)) {
    return value;
  }
  if (Math.abs(value) > 999999) {
    return `${Math.sign(value) * ((Math.abs(value) / 1000000).toFixed(1))}M`;
  }
  if (Math.abs(value) > 999) {
    return `${Math.sign(value) * ((Math.abs(value) / 1000).toFixed(1))}K`;
  }
  return Math.ceil((Math.sign(value) * Math.abs(value)).toFixed(0) / 10) * 10;
};

export const round25 = (value) => {
  value = +((Math.round(value * 4) / 4).toFixed(2));
  if (Number.isInteger(value)) {
    return Math.trunc(value);
  }
  return value;
};

export const formatPercent = (value) => {
  return Math.round(value * 100);
};

export const formatIncomeLevel = (value) => {
  const info = value.slice(7).split("_");
  if (info[0] === "over") {
    return `$${info[1]}+`;
  }
  return info.map((v) => `$${v}k`).join(" - ");
};

export const formatGenderAge = (value) => {
  const info = value.split("_");
  if (info[1] === "over") {
    return `${info[0]}/${info[2]}+`;
  }
  return `${info[0]}/${info[1]} - ${info[2]}`;
};

export const formatGender = (value) => {
  const info = value.split("_");
  return info[0];
};

export const formatAge = (value) => {
  const info = value.split("_");
  if (info[1] === "over") {
    return `${info[2]}+`;
  }
  return `${info[1]} - ${info[2]}`;
};

export const formatHour = (hour) => {
  if (hour) {
    hour = parseInt(hour.replace(/extrapolated_hh|hh/g, ""), 10);
    if (hour === 0) {
      hour = "12:00 am";
    } else if (hour === 12) {
      hour = "12:00 pm";
    } else if (hour > 12) {
      hour = `${hour - 12}:00 pm`;
    } else {
      hour = `${hour}:00 am`;
    }
    return hour;
  }
  return "";
};

/**
 * Unfurl Date Range Array
 *
 * @param {Array}  dates                       Array representing Date Range
 * @param {Object} [options]                   Options object
 * @param {String} [options.interval]          Interval to unfurl to [month|day]
 * @param {Boolean} [options.returnFormatted]  Flag to format dates before returning
 *
 * @returns {Array} array of dates
 */
export const unfurlDates = (dateRange = {}, { interval = "month" } = {}) => {
  const isMonthly = ["month", "monthly"].includes(interval);
  let { start, end } = mapValues(dateRange, (d) => isDate(d) ? d : parseISO(d));
  end = end || start;
  if (isBefore(end, start)) {
    const start2 = start;
    start = end;
    end = start2;
  }
  if (isMonthly) {
    end = lastDayOfMonth(end);
  }
  let unfurledDates = isMonthly ? eachMonthOfInterval({ start, end }) : eachDayOfInterval({ start, end });
  return unfurledDates;
};

/**
 * Unfurl Nested Date Range Array
 *
 * @param {Array}  dates                       Nested Array of Date Ranges
 * @param {Object} [options]                   Options object
 * @param {String} [options.interval]          Interval to unfurl to [month|day]
 *
 * @returns {Array} array of dates
 */
export const unfurlNestedDates = (dates = [], options = {}) => {
  if (!Array.isArray(dates)) {
    dates = [dates];
  }
  return uniq(flatten(dates.map((dr) => {
    return unfurlDates(dr, options);
  })));
};

/**
 * Check if two dates are consecutative
 *
 * @param {String} date1             First date to compare
 * @param {String} date2             Second date to compare
 * @param {Object} options           Options
 * @param {Boolean} options.monthly  Comparing months instead
 *
 * @returns {Boolean} True|False if dates are consecutative
 */
export const isConsecutative = (date1, date2, { monthly = false }) => {
  date1 = isDate(date1) ? date1 : parseISO(date1);
  date2 = isDate(date2) ? date2 : parseISO(date2);

  if (isBefore(date2, date1)) {
    const date12 = date1;
    date1 = date2;
    date2 = date12;
  }

  if (monthly) {
    return isSameMonth(date1, subMonths(date2, 1));
  }
  return isSameDay(date1, subDays(date2, 1));
};

export const getBlockGroupNameFromFips = (fips) => {
  return `Block Group ${fips.slice(-1)}`;
};

export const getTractNameFromFips = (fips) => {
  return `Census Tract ${trimEnd(`${fips.slice(5, 11) / 100}`, ".00")}`;
};
