<template>
  <RouterLink class="flex items-center p-2 text-zinc-400 rounded-lg hover:bg-zinc-50/5 hover:text-zinc-50" active-class="bg-zinc-50/5 text-zinc-50" :to="route">
    <slot name="icon"></slot>
    <span class="ml-3 capitalize">{{ startCase(to) }}</span>
  </RouterLink>
</template>

<script setup>
import { computed } from "vue";
import { RouterLink } from "vue-router";
import { startCase } from "lodash";

const props = defineProps({
  to: {
    type: String,
    default: ""
  }
});

const route = computed(() => ({
  name: props.to
}));
</script>