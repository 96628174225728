<template>
  <div>
    <div class="pb-1 font-medium text-lg">{{ title }}</div>
    <div class="h-96 cursor-default" ref="barChart"></div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { keys, isEmpty, values, sum, pickBy, startsWith, mapKeys, max, min } from "lodash";
import { scaleLinear, scaleBand } from "d3-scale";
import { select } from "d3-selection";
import "d3-transition";
import { axisLeft } from "d3-axis";

const props = defineProps({
  title: String,
  data: {
    type: Object,
    default: () => {}
  }
});

const barChart = ref(null);

const aggregations = {
  age_75_over: ["75_79", "80_84", "over_85"],
  age_60_74: ["60_61", "62_64", "65_66", "67_69", "70_74"],
  age_45_59: ["45_49", "50_54", "55_59"],
  age_30_44: ["30_34", "35_39", "40_44"],
  age_15_29: ["15_17", "18_19", "20_20", "21_21", "22_24", "25_29"],
  age_0_14: ["0_5", "5_9", "10_14"]
};

async function drawbarChart() {
  if (!isEmpty(props.data)) {
    const width = barChart.value.clientWidth;
    const height = barChart.value.clientHeight;
    const padding = { top: 40, right: 60, bottom: 40, left: 60 };

    const total = sum(values(props.data).map((v) => +v));
    let male = mapKeys(pickBy(props.data, (v, k) => startsWith(k, "male_")), (v, k) => k.replace("male_", ""));
    male = Object.entries(aggregations).reduce((result, [category, entries]) => {
      result.push({
        group: category.replace("age_", "").replace("_", "-").replace("-over", "+"),
        value: (sum(values(pickBy(male, (v, k) => entries.includes(k))).map((v) => +v)) / total) * 100
      });
      return result;
    }, []).reverse();
    let female = mapKeys(pickBy(props.data, (v, k) => startsWith(k, "female_")), (v, k) => k.replace("female_", ""));
    female = Object.entries(aggregations).reduce((result, [category, entries]) => {
      result.push({
        group: category.replace("age_", "").replace("_", "-").replace("-over", "+"),
        value: -(sum(values(pickBy(female, (v, k) => entries.includes(k))).map((v) => +v)) / total) * 100
      });
      return result;
    }, []).reverse();

    const categories = keys(aggregations).reverse().map((k) => k.replace("age_", "").replace("_", "-").replace("-over", "+"));
    const x = scaleLinear()
      .domain([min(female.map((d) => d.value)), max(male.map((d) => d.value))])
      .range([padding.left, width - padding.right]);
    const y = scaleBand()
      .domain(categories)
      .range([height - padding.bottom, padding.top])
      .padding(0.1);

    // create container
    select(barChart.value).selectChildren("*").remove();
    const svg = select(barChart.value).append("svg")
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [0, 0, width, height]);

    // male
    svg.selectAll("bars").data(male).enter().append("rect")
      .attr("x", x(0))
      .attr("y", (d) => y(d.group))
      .attr("width", (d) => x(d.value) - x(0))
      .attr("height", y.bandwidth())
      .attr("fill", "#247BA0");

    svg.selectAll("labels").data(male).enter().append("text")
      .text((d) => `${Math.abs(d.value.toFixed(0))}%`)
      .attr("transform", (d) => `translate(${max([x(0) + 45, x(0) + ((x(d.value) - x(0)) / 2)])}, ${y(d.group) + y.bandwidth() / 2 + 8})`)
      .style("text-anchor", "middle")
      .style("fill", "white")
      .style("font-size", 16);

    // female
    svg.selectAll("bars").data(female).enter().append("rect")
      .attr("x", (d) => x(0) - (x(0) - x(d.value)))
      .attr("y", (d) => y(d.group))
      .attr("width", (d) => x(0) - x(d.value))
      .attr("height", y.bandwidth())
      .attr("fill", "#FF1654");

    svg.selectAll("labels").data(female).enter().append("text")
      .text((d) => `${Math.abs(d.value.toFixed(0))}%`)
      .attr("transform", (d) => `translate(${min([x(0) - 45, x(0) + ((x(d.value) - x(0)) / 2)])}, ${y(d.group) + y.bandwidth() / 2 + 8})`)
      .style("text-anchor", "middle")
      .style("fill", "white")
      .style("font-size", 16);

    svg.append("g")
      .attr("transform", `translate(${padding.left}, 0)`)
      .call(axisLeft(y).tickPadding(12))
      .call((g) => g.select(".domain").remove())
      .call((g) => g.selectAll(".tick line").remove())
      .call((g) => g.selectAll(".tick")
        .attr("fill", "#E5E7EB")
        .attr("font-size", 12)
      );
  }
}

onMounted(drawbarChart);

watch(() => props.data, drawbarChart, { immediate: true, deep: true });
</script>

<style>
@import "@/assets/style/main.css";
</style>