<template>
  <div>
    <div class="pb-1 font-medium text-lg">{{ title }}</div>
    <div class="h-96 cursor-default" ref="heatmapChart"></div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from "vue";
import { isEmpty, flatten, values, mapValues } from "lodash";
import { scaleBand, scaleQuantile } from "d3-scale";
import { schemeBlues } from "d3-scale-chromatic";
import { axisBottom, axisLeft } from "d3-axis";
import { select } from "d3-selection";

const props = defineProps({
  title: String,
  data: {
    type: Object,
    default: () => {}
  }
});

const heatmapChart = ref(null);
const weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
const hours = ["hh06", "hh07", "hh08", "hh09", "hh10", "hh11", "hh12", "hh13", "hh14", "hh15", "hh16", "hh17", "hh18", "hh19", "hh20", "hh21", "hh22", "hh23"];

function drawHeatmap () {
  if (!isEmpty(props.data)) {
    const width = heatmapChart.value.clientWidth;
    const height = heatmapChart.value.clientHeight;
    const padding = { top: 0, right: 40, bottom: 60, left: 40 };

    // clear before drawing
    select(heatmapChart.value).selectChildren("*").remove();
    const svg = select(heatmapChart.value).append("svg")
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [0, 0, width, height]);

    const x = scaleBand().domain(weekdays).range([0, width - padding.left - padding.right]).padding(0.05);
    const y = scaleBand().domain(hours.reverse()).range([height - padding.bottom, padding.top]).padding(0.05);
    const colorScale = scaleQuantile().domain(flatten(values(mapValues(props.data, (d) => values(d))))).range(schemeBlues[9]);

    // Y labels
    svg.append("g")
      .style("font-size", 14)
      .style("text-rendering", "optimizeLegibility")
      .style("font-family", "Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif")
      .attr("transform", `translate(${padding.left}, ${padding.top})`)
      .call(axisLeft(y).tickSize(0).tickFormat((hour) => {
        hour = parseInt(hour.replace("hh", ""));
        if (hour === 0) {
          hour = "12am";
        } else if (hour === 12) {
          hour = "12pm";
        } else if (hour > 12) {
          hour = `${hour - 12}pm`;
        } else {
          hour = `${hour}am`;
        }
        return hour;
      }))
      .call((g) => g.select(".domain").remove())
      .call((g) => g.selectAll(".tick line").remove());

      // X labels
      svg.append("g")
      .style("font-size", 14)
      .attr("transform", `translate(${padding.left}, ${(height - padding.bottom + padding.top)})`)
      .call(axisBottom(x).tickSize(0))
      .call((g) => g.select(".domain").remove())
      .call((g) => g.selectAll(".tick line").remove());

    // matrix
    const matrixContainer = svg.append("g").attr("transform", `translate(${padding.left}, ${padding.top})`);
    matrixContainer.selectAll().data(flatten(values(mapValues(props.data, (v, weekday) => {
      const data = [];
      for (const [hour, count] of Object.entries(v)) {
        data.push({ weekday, hour, count });
      }
      return data;
    })))).enter()
      .append("rect")
      .attr("x", (d) => x(d.weekday))
      .attr("y", (d) => y(d.hour))
      // .attr("rx", 4)
      // .attr("ry", 4)
      .attr("width", x.bandwidth())
      .attr("height", () => y.bandwidth())
      .style("fill", (d) => colorScale(d.count))
      .style("stroke-width", 4)
      .style("stroke", "none")
      .style("opacity", 1.0);
  }
}

onMounted(drawHeatmap);
watch(() => props.data, drawHeatmap, { immediate: true, deep: true });
</script>
